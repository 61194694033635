import { notifications } from '@/app/notifications'
import AppButton from '@theme/components/atom/AppButton'
import AppImage from '@theme/components/atom/AppImage'
import AppInput from '@theme/components/atom/AppInput'
import AppTextarea from '@theme/components/atom/AppTextarea'
import AppTitle from '@theme/components/atom/AppTitle'
import LoadingMixin from '~/mixins/LoadingMixin'
import Modal from '@theme/components/utils/Modal'
import ProvideValidator from '~/app/ProvideValidator'
import RequiredSign from '@theme/components/atom/RequiredSign'

export default {
  components: {
    AppButton,
    AppImage,
    AppInput,
    AppTextarea,
    AppTitle,
    Modal,
    RequiredSign,
  },
  props: {
    title: {
      type: String,
      default() {
        return this.$t('IssueFormInModal.title')
      },
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
  mixins: [LoadingMixin, ProvideValidator],
  data() {
    return {
      loading: false,
      submitted: false,
      input: {
        email: '',
        name: '',
        question: '',
      },
      slugPrivacyPolicy: this.$t('globals.pageSlugs.privacyPolicy'),
    }
  },
  created() {
    this.CLASS_ELEMENT = 'col-sm-16 col-lg-14'
    this.CLASS_ELEMENTS_OUTSIDE_NO_LABEL = 'offset-sm-6 offset-lg-6 pl-2'
    this.CLASS_LABEL = 'col-form-label col-sm-6 col-lg-6'
    this.CLASS_ROW = 'form-group row'
    this.CLASS_TEXTAREA = 'col-sm-16 col-lg-18'
  },
  methods: {
    open() {
      this.$refs.modal.show()
    },
    hide() {
      this.$refs.modal.hide()
    },
    async submit() {
      const isValid = await this.$validator.validate()
      if (!isValid) return
      this.startLoading()
      const response = await this.$store.dispatch('user/CONTACT_FORM', {
        email: this.input.email,
        name: this.input.name,
        text: this.input.question,
      })
      if (response) {
        notifications.success(this.$t('IssueFormInModal.notifications.success'))
        this.input.email = ''
        this.input.name = ''
        this.input.question = ''
        this.hide()
      }
      this.stopLoading()
    },
  },
}
